import React from "react"
import HeaderPaginas from "../../../components/headerPaginas"
import Layout from "../../../components/layout"
import Seo from "../../../components/SEO/SEO"
import {
  GrTransaction,
  GrSystem,
  GrSave,
  GrAscend,
  GrTime,
  GrRun,
} from "react-icons/gr"
import { IconContext } from "react-icons"
import Boton from "../../../components/boton"

export default function servidorDedicado(props) {
  return (
    <Layout>
      <Seo pathname={props.location.pathname} />
      <div className="cabecera-servicio">
        <HeaderPaginas
          titulo="Servidor dedicado"
          imagen="/img/cabeceras/servidor-dedicado-bullhost.jpg"
        />
        <div className="intro-servicio">
          <p>
            Porque no solo quieres un servidor. Quieres un servidor con acceso
            exclusivo y control total, asesorado por técnicos y consultores
            tecnológicos de primer nivel: sin líos.
          </p>
        </div>
      </div>
      <div className="cuerpo-servicio">
        <section className=" limitador bloque-grid">
          <div className="titulo destacado">
            <h2>¿Qué es?</h2>
            {/* <h3>¿Qué es y cuáles son sus ventajas?</h3> */}
          </div>
          <div className="texto">
            <p>
              Los servidores dedicados son potentes equipos informáticos cuyo
              uso está destinado solo a ti, y en el que podrás disponer de todos
              los recursos de dicha máquina: se alquilan por completo. Dale al
              "bare metal" con los mejores técnicos de infraestructuras de
              España.
            </p>
          </div>
        </section>
        <section className=" limitador bloque-grid">
          <div className="titulo destacado">
            <h2>¿Por qué?</h2>
            {/* <h3>¿Para quién y por qué un VPS?</h3> */}
          </div>
          <div className="texto">
            <p>
              Porque no todo es compartir en esta vida y a veces se necesita un
              servidor de uso exclusivo para garantizar al máximo la seguridad y
              el rendimiento.
            </p>
          </div>
        </section>
        <section className=" limitador bloque-grid">
          <div className="titulo destacado">
            <h2>¿Para quién?</h2>
            {/* <h3>¿Dónde se alojan nuestros VPS?</h3> */}
          </div>
          <div className="texto">
            <p>
              Servidores para proyectos exigentes o entornos críticos, como un
              software de gestión (ERP).
            </p>
          </div>
        </section>
        <section className="">
          <div className="bloque-tips limitador" id="tips-serv-dedicado">
            <h2>Características destacadas</h2>
            <IconContext.Provider
              value={{
                fill: "#1f1f1f",
                stroke: "#1f1f1f",
                color: "#1f1f1f",
                size: "40%",
                className: "serv-icono-svg",
              }}
            >
              <ul>
                <li>
                  <div className="tip-icono">
                    <GrTransaction />
                  </div>
                  <p>Transferencia ilimitada</p>
                </li>
                <li>
                  <div className="tip-icono">
                    <GrSystem />
                  </div>
                  <p>Sistema operativo bajo demanda</p>
                </li>
                <li>
                  <div className="tip-icono">
                    <GrSave />
                  </div>
                  <p>Backup incluido</p>
                </li>
                <li>
                  <div className="tip-icono">
                    <GrRun />
                  </div>
                  <p>Alto rendimiento</p>
                </li>
                <li>
                  <div className="tip-icono">
                    <GrTime />
                  </div>
                  <p>Alta disponibilidad</p>
                </li>
                <li>
                  <div className="tip-icono">
                    <GrAscend />
                  </div>
                  <p>Escalabilidad</p>
                </li>
              </ul>
            </IconContext.Provider>
          </div>
        </section>
      </div>
      {/* CTA para contacto */}
      <section className="bloque-oscuro contacto-background">
        <div className="overlay-contacto"></div>
        <div className="header-inicio limitador">
          <div className="detalle destacado">
            <p>Contacto</p>
          </div>
          <div className="titulo">
            <h2>
              Nuestro equipo se encarga del diseño, montaje y mantenimiento de
              todas nuestras máquinas.
            </h2>
            <p>¿Quieres un presupuesto personalizado?</p>
            <Boton
              color="secundario"
              url="/contacto/"
              texto="Contactar"
              externo={false}
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}
